<template>
  <div class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden" id="email-app">

    <vx-card>

      <div class="flex flex-wrap items-center justify-end">
        <vs-input class="inputx w-1/2" label="Article Title" placeholder="Article Title" v-model="title"/>

        <vs-button @click="promptForAction" class="ml-auto mt-2" color="primary">Create Article</vs-button>
      </div>

      <br>

      <quill-editor v-model="content"></quill-editor>

    </vx-card>


  </div>
</template>

<script>

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import {quillEditor} from 'vue-quill-editor';

export default {
  name: 'BlogEdit',
  components: {
    quillEditor,
  },
  data() {
    return {
      content: '',
      title: '',
    };
  },
  methods: {
    promptForAction() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure you want to publish this article?',
        accept: this.initiateArticleCreate,
      });
    },
    initiateArticleCreate() {

      this.$vs.loading();

      this.$http
        .post('blog', {
          content: this.content,
          title: this.title,
        })
        .then((response) => {
          this.$vs.loading.close();
          if (response.data.data.id) {
            this.$router.push(`/a/blogs/${response.data.data.id}`);
          }
          return this.$vs.notify({
            color: 'success',
            title: 'Created Article',
            text: 'Your article has been created',
          });
        })
        .catch(exception => {
          this.$vs.loading.close();
          if (exception.response) {

            const response = exception.response;

            if (response.status === 400) {
              return this.$vs.notify({
                title: 'Failed to create blog',
                text: response.data.error.description,
                color: 'danger',
                position: 'top-right',
              });
            }

          }

          return this.$vs.notify({
            title: 'Failed to load blog',
            text: 'An unknown error occurred while creating the blog. This has been reported.',
            color: 'danger',
            position: 'top-right',
          });
        });

    },
  },
};
</script>

<style scoped>

</style>
